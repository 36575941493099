<template>
  <main id="content" role="main">

    <!-- Hero Section -->
    <div class="gradient-y-overlay-lg-white position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/img/resource-library/case-study/hsbc/hsbc-background.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/resource-library"> -->
                  <router-link :to="{ path: '/resource-library' }">
                    Resource Library
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Case Study - HSBC x beNovelty Local Business Story in Open APIs</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- End Breadcrumb -->

      <div class="container space-2 space-bottom-3">
        <div class="w-lg-75 text-center mx-lg-auto">
          <span class="d-block small font-weight-bold text-cap mb-2">Case study</span>
          <h2>HSBC x beNovelty Local Business Story in Open APIs</h2>
        </div>
      </div>
    </div>
    <!-- End Hero Section -->

    <div class="container space-2 space-top-lg-3">
      <div class="w-lg-100 mx-auto">
        <div class="row align-items-md-center mb-11">
          <div class="col-md-6 order-md-2 mb-3 mb-md-0">
            <div class="card">
              <div class="card-body mx-auto">
                <a
                  class="js-inline-video-player video-player-btn video-player-centered"
                  href="https://www.youtube.com/watch?v=ASfA4cA_PpU"
                  target="_blank"
                >
                  <span id="ga-case-study-hsbc-play-video-button" class="video-player-icon">
                    <i class="fas fa-play" />
                  </span>
                </a>

                <img class="img-fluid" src="@/assets/img/resource-library/case-study/hsbc/hsbc-video-thumbnail.jpg">
              </div>
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="mb-3">
              <h3>Local Business Story in Open APIs</h3>
            </div>
            <p>Watch the video to learn how Hong Kong businesses embrace changes and flourish in the new normal.</p>
            <div class="mt-3">
              <a id="ga-case-study-hsbc-watch-video-button" class="font-weight-bold" href="https://www.youtube.com/watch?v=ASfA4cA_PpU" target="_blank">Watch Now<i class="fas fa-angle-right fa-sm ml-2" /></a>
            </div>
          </div>
        </div>

        <div class="row align-items-md-center mb-11">
          <div class="col-md-6 order-md-1 mb-3 mb-md-0">
            <div class="card">
              <div class="card-body mx-auto">
                <img class="img-fluid" src="@/assets/img/resource-library/case-study/hsbc/hsbc-blog-cover.jpg">
              </div>
            </div>
          </div>
          <div class="col-md-6 order-md-2">
            <div class="mb-3">
              <h3>Dedicating to Establish a Comprehensive Open API Ecosystem</h3>
            </div>
            <p>Read the blog to find out beNovelty's business journey of leveraging technology products and services to help enterprises and communities well adapted to the new normal.</p>
            <div class="mt-3">
              <a id="ga-case-study-hsbc-learn-more-button" class="font-weight-bold" href="https://blog.openapihub.com/en-us/hsbc-benovelty-local-business-stories" target="_blank">Learn More<i class="fas fa-angle-right fa-sm ml-2" /></a>
            </div>
          </div>
        </div>

        <div class="row align-items-md-center mb-11">
          <div class="col-md-6 order-md-2 mb-3 mb-md-0">
            <div class="card">
              <div class="card-body p-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/consultancy-overview-2.svg">
              </div>
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="mb-3">
              <h3>A Continuing Success Story</h3>
            </div>
            <p>With the aim of leveraging Open API platform and connecting different industries, beNovelty will continue to work closely with HSBC to meet demand for digital transformation across industries.</p>
            <div class="mt-3">
              <a id="ga-case-study-hsbc-read-story-button" class="font-weight-bold" href="https://www.business.hsbc.com.hk/en-gb/local-business-stories-benovelty" target="_blank">Read the Complete Story on HSBC Site<i class="fas fa-angle-right fa-sm ml-2" /></a>
            </div>
          </div>
        </div>

        <div class="mt-11">
          <div class="card" data-aos="fade-up">
            <div class="card-body p-0">
              <img class="img-fluid" src="@/assets/img/resource-library/case-study/hsbc/hsbc-gallery.jpg">
            </div>
          </div>
          <span class="small float-right mt-1">*Selected images are from HSBC Commercial Banking – Local Business Stories – beNovelty &amp; Bus Shelter Ads</span>
        </div>
      </div>
    </div>

    <!-- Testimonials Section -->
    <div class="overflow-hidden space-2" data-aos="fade-up">
      <div class="position-relative bg-light text-center rounded mx-3 mx-md-11">
        <div class="container space-1 space-md-2 space-lg-3 position-relative z-index-2">
          <div class="w-lg-75 mx-lg-auto">
            <!-- SVG Quote -->
            <figure class="mx-auto text-center mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="48px"
                height="48px"
                viewBox="0 0 8 8"
                style="enable-background:new 0 0 8 8;"
                xml:space="preserve"
              >
                <path
                  fill="#57abf0"
                  d="M3,1.3C2,1.7,1.2,2.7,1.2,3.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5
                C1.4,6.9,1,6.6,0.7,6.1C0.4,5.6,0.3,4.9,0.3,4.5c0-1.6,0.8-2.9,2.5-3.7L3,1.3z M7.1,1.3c-1,0.4-1.8,1.4-1.8,2.3
                c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5c-0.7,0-1.1-0.3-1.4-0.8
                C4.4,5.6,4.4,4.9,4.4,4.5c0-1.6,0.8-2.9,2.5-3.7L7.1,1.3z"
                />
              </svg>
            </figure>
            <!-- End SVG Quote -->

            <!-- Blockquote -->
            <div class="text-center mb-5">
              <blockquote class="lead">With our extensive client network and industry-leading position, we are pleased to find synergy with beNovelty’s technology capability and together help companies leverage technology and enhance the public’s everyday experience.</blockquote>
            </div>
            <!-- End Blockquote -->

            <!-- Reviewer -->
            <div class="w-lg-50 text-center mx-lg-auto">
              <h4 class="mb-0">Terence Chiu</h4>
              <p class="text-muted">Head of Commercial Banking, Hong Kong, HSBC</p>
            </div>
            <!-- End Reviewer -->

            <!-- SVG Shapes -->
            <figure class="max-w-15rem w-100 position-absolute bottom-0 right-0 d-none d-md-block">
              <div class="mb-n7 mr-n7">
                <img class="img-fluid" src="@/assets/svg/components/dots-4.svg">
              </div>
            </figure>
            <!-- End SVG Shapes -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Testimonials Section -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-bottom-3">
      <ContactUsBar :detail="{ title: 'Interested in Working with Us?' }" />
    </div>
    <!-- ========== End CTA Section ==========  -->

  </main>
</template>

<script>
import 'bootstrap'
import ContactUsBar from '@/components/contact-us-bar'
export default {
  name: 'HSBC',
  components: {
    ContactUsBar
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'HSBC x beNovelty Local Business Story in Open APIs | Case Study | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/case-study/hsbc' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'HSBC x beNovelty Local Business Story in Open APIs | Case Study | beNovelty' },
        { property: 'og:description', content: 'HSBC Accelerates Technology Innovation with beNovelty\'s API Solutions' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/case-study/hsbc' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
