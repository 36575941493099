<template>
  <div class="w-lg-85 mx-lg-auto">
    <div class="card bg-primary text-white overflow-hidden p-4">
      <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
        <div class="col-md-6 offset-md-3 mb-3 mb-md-0">
          <h3 class="text-white mb-1">{{ detail.title }}</h3>
          <p v-if="detail.content" class="text-white-70 mb-0">{{ detail.content }}</p>
        </div>
        <div class="col-md-3 text-md-right">
          <!-- <a id="ga-cc-cta-type-1-button" class="btn btn-light transition-3d-hover" href="/contact">Contact Us</a> -->
          <router-link id="ga-cc-cta-type-1-button" class="btn btn-light transition-3d-hover" :to="{ path: '/contact' }">Contact Us</router-link>
        </div>
      </div>

      <!-- SVG Component -->
      <figure class="w-25 d-none d-md-block content-centered-y ml-n4">
        <img class="img-fluid" src="@/assets/svg/illustrations/communication.svg">
      </figure>
      <!-- End SVG Component -->
    </div>
  </div>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '../assets/vendor/hs-go-to/src/js/hs-go-to'

export default {
  name: 'ContactUsBar',
  components: {
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  methods: {
    scrollToFooter() {
      window.scrollTo({
        top: $('#footer').offset().top,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.css");
</style>
